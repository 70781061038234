// extracted by mini-css-extract-plugin
export var basicContactForm = "contact-module--basicContactForm--d7038";
export var basicContentForm = "contact-module--basicContentForm--e1ade";
export var butSubmit = "contact-module--butSubmit--ed17a";
export var commonForms = "contact-module--commonForms--8ecde";
export var confirmBox = "contact-module--confirmBox--03ca6";
export var confirmButOK = "contact-module--confirmButOK--3c912";
export var confirmMessage = "contact-module--confirmMessage--9d29d";
export var confirmRegion = "contact-module--confirmRegion--42956";
export var contactBody = "contact-module--contactBody--c1e5c";
export var darkenedBG = "contact-module--darkenedBG--84bdc";
export var eventRequestForm = "contact-module--eventRequestForm--282c3";
export var flexCol = "contact-module--flexCol--1625b";
export var flexRow = "contact-module--flexRow--d5235";
export var inputOrg = "contact-module--inputOrg--585dd";
export var inputOrgAudienceSize = "contact-module--inputOrgAudienceSize--94228";
export var inputOrgDetBox = "contact-module--inputOrgDetBox--86e4a";
export var inputOrgMissionBox = "contact-module--inputOrgMissionBox--e8203";
export var inputOrgRadio = "contact-module--inputOrgRadio--01b97";
export var inputPerCountryCode = "contact-module--inputPerCountryCode--844e1";
export var labelOrgRadio = "contact-module--labelOrgRadio--43e0e";
export var reqSummary = "contact-module--reqSummary--2c484";
export var sublabelText = "contact-module--sublabelText--47a86";